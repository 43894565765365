<template>
    <div class="systemInfoBox">
        <p>{{userName}}进入直播间</p>
    </div>
</template>
  
<script>

export default {
    name: 'SystemInfo',
    props: {
        userName: {
            type: String
        }
    },
    data() {
        return {}
    }
}
</script>
  
<style lang="scss">
.systemInfoBox {
    width: 100%;
    height: 14px;
    margin-bottom: 26px;

    p {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #727272;
    }
}
</style>