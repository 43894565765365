import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import 'normalize.css/normalize.css'
import '@/style/index.scss'
import '@/assets/font/font.css.scss'

new Vue({
  render: h => h(App),
}).$mount('#app')
