export default class SocketService {
    /**
     * 单例
     */
    static instance = null
    static get Instance() {
      if (!this.instance) {
        this.instance = new SocketService()
      }
      return this.instance
    }

    // 和服务端连接的socket对象
    ws = null
    socketUrl = null

    // 存储回调函数
    callBackMapping = {}

    // 标识是否连接成功
    connected = false

    // 记录重试的次数
    sendRetryCount = 0

    // 重新连接尝试的次数
    connectRetryCount = 0

    //  定义连接服务器的方法
    connect(url) {
      // 连接服务器
      if (!window.WebSocket) {
        return console.log('您的浏览器不支持WebSocket')
      }
      this.ws = new WebSocket(url)
      // 连接成功的事件
      this.ws.onopen = () => {
        console.log('连接服务端成功')
        this.connected = true
        //重新加入直播间
         let userId =  localStorage.getItem("userId"),
          nickname = localStorage.getItem("nickname"),
          liveId = localStorage.getItem("liveId");
            let initParams = {
              "type": 'reinit',
              "userid": userId + '',
              "username": nickname + '',
              "avatar": 'sdsdsd',
              "content": '',
              "roomid": liveId
          }
          // 发送信息
          setTimeout(() => {
              console.log('initParams')
              this.send(JSON.stringify(initParams))
          }, 1000);
        // 重置重新连接的次数
        this.connectRetryCount = 0
      }
      //   // 1.连接服务端失败
      //   // 2.当连接成功之后, 服务器关闭的情况(连接失败重连)
      this.ws.onclose = () => {
        console.log('连接服务端失败')
        this.connected = false
        this.connectRetryCount++
        setTimeout(() => {
          console.log('重连')
          this.connect(this.socketUrl)
        }, 2000)
      }
      // 得到服务端发送过来的数据
      this.ws.onmessage = msg => {
        console.log('从服务端获取到了数据' + msg.data)
        if (this.callBackMapping) {
          this.callBackMapping.call(this, msg.data)
        }
      }
    }
    // 回调函数的注册
    registerCallBack (callBack) {
      this.callBackMapping = callBack
    }

    // 取消某一个回调函数
    unRegisterCallBack(callBack){
      console.log('取消某一个回调函数', callBack)
      this.callBackMapping = null
    }

    // 发送数据的方法
    send (data) {
      console.log('发送ws信息', data)
      // 判断此时此刻有没有连接成功
      try {
        if (this.connected) {
          this.sendRetryCount = 0
          this.ws.send(data)
        } else {
          this.sendRetryCount++
          setTimeout(() => {
            this.send(data)
          }, this.sendRetryCount * 500)
        }
      } catch (error) {
        console.log(error)
      }
    }

  }

