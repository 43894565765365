<template>
    <div>
        <div v-if="direction === 'left'" class="chatBoxLeft">
            <div class="avart">
                <img :src="avatarUrl" alt="">
            </div>
            <div class="comment">
                <p class="userName">{{ userName }}</p>
                <div class="contentCard">
                    <div class="triangle"></div>
                    <p class="content">{{ content }}</p>
                </div>
            </div>
        </div>
        <div v-if="direction === 'right'" class="chatBoxRight">
            <div class="comment">
                <div class="contentCard">
                    <p class="content">{{ content }}</p>
                    <div class="triangle"></div>
                </div>
            </div>
            <div class="avart">
                <img :src="avatarUrl" alt="">
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ChatBox',
    props: {
        avatarUrl: {
            type: String,
        },
        userName: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        },
        direction: {
            type: String,
            default: 'left'
        }

    },
    data() {
        return {}
    }
}
</script>

<style lang="scss">
.chatBoxLeft {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 26px;

    .avart {
        width: 30.8px;
        height: 30.8px;
        border-radius: 50%;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }

    .comment {
        text-align: left;
        margin-left: 7px;

        .userName {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #727272;
            margin-left: 8px;
        }

        .contentCard {
            margin-top: 8px;
            width: auto;
            position: relative;
            display: flex;
            justify-content: flex-start;
            max-width: 254px;

            .triangle {
                margin-top: 7.3px;
                width: 0;
                height: 0;
                border-right: 8px solid #FFFFFF;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
            }

            .content {
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #000000;
              background-color: #FFFFFF;
              height: auto;
              min-height: 30px;
              max-width: 266px;
              padding: 7px;
              word-wrap: break-word;
            }
        }
    }
}

.chatBoxRight {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;

    .avart {
        width: 35.8px;
        height: 35.8px;
        border-radius: 50%;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }

    .comment {
        text-align: left;
        margin-right: 7px;

        .contentCard {
            width: auto;
            position: relative;
            display: flex;
            justify-content: flex-start;
            max-width: 254px;

            .triangle {
                margin-top: 7.3px;
                width: 0;
                height: 0;
                border-left: 8px solid #8EBC63;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
            }

            .content {
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                background-color: #8EBC63;
                height: auto;
                min-height: 30px;
                max-width: 266px;
                padding: 7px;
                word-wrap: break-word;
            }
        }
    }
}
</style>
