import request from '@/api/request'; // 引入封装好的axios

// 获取用户账户信息
export function getLoginTemp (params) {
    return request({
        url: 'm.api?_gp=user&_mt=loginTemp',
        method: 'get',
        params
    })
}

// 根据token获取用户账户信息
export function getInfoByToken (token) {
    let params={Token:token}
    return request({
        url: 'm.api?_gp=user&_mt=getInfo',
        method: 'get',
        params
    })
}

// 获取用户在直播间中的状态
export function getLiveUserStatus (params) {
    return request({
        url: 'm.api?_gp=live&_mt=liveUserStatus',
        method: 'get',
        params
    })
}

// 直播预约
export function getAppointment (params) {
    return request({
        url: 'm.api?_gp=live&_mt=appointment',
        method: 'get',
        params
    })
}

// 直播预约状态
export function getAppointmentState (params) {
    return request({
        url: 'm.api?_gp=live&_mt=appointmentTest',
        method: 'get',
        params
    })
}

// 直播详情
export function getCurrentLive (params) {
    return request({
        url: 'm.api?_gp=live&_mt=getCurrentLive',
        method: 'get',
        params
    })
}

// 直播地址
export function getLiveAddress (params) {
    return request({
        url: 'm.api?_gp=live&_mt=liveAddress',
        method: 'get',
        params
    })
}

// 回放视频
export function getReplayAddress (params) {
    return request({
        url: 'm.api?_gp=live&_mt=liveReplayAddress',
        method: 'get',
        params
    })
}
