import axios from 'axios';
import qs from 'qs';

// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_API, // api的base_url
    // withCredentials: true, // 跨域请求时是否发送cookies
    timeout: 30000 // 请求超时设置
})

// 请求拦截器
service.interceptors.request.use(config => {
    config.method === 'post'        
    ? config.data = qs.stringify({...config.data})        
    : config.params = {...config.params};    
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'

    // console.log(qs.stringify(config.data));

    return config;
}, error => {
    // 处理请求错误
    console.log(error); // 用于调试
    return Promise.reject(error);
})

// 响应拦截器
service.interceptors.response.use(response => {
    // let res = respone.data; // 如果返回的结果是data.data的，嫌麻烦可以用这个，return res
    return response.data;
}, error => {
    console.log('error：' + error); // 用于调试
    return Promise.reject(error);
})

export default service;
