<template>
  <div class="contain">
    <div v-if="isShowLive" id="dplayer" v-show="(liveStatus == 1) || (liveStatus == 3)"></div>
    <div id="placeholdeImg" v-show="(liveStatus == 2) || (liveStatus == 0)">
      <img :src="placeholdeImg" alt="">
    </div>
    <div class="right-box">
      <div data-v-210f7d14="" class="types-div flex" v-if="liveStatus == 1 || liveStatus == 3">
        <ul data-v-210f7d14="" class="tab-list">
          <li data-v-210f7d14="" :class="{'tab-active': tabIndex == 0}" @click="tabClick('0')">聊天室</li>
          <li data-v-210f7d14="" :class="{'tab-active': tabIndex == 1}" @click="tabClick('1')">文档</li>
        </ul>
        <div data-v-210f7d14="" class="flex-vertical" style="height: 40px;line-height: 40px;"><span data-v-210f7d14=""> 共<i
            data-v-210f7d14="" class="search-result-label"> {{roomInfo.filelist && roomInfo.filelist.length || 0}} </i>项资源</span></div>
      </div>
      <div v-if="tabIndex == 1" class=" list">
        <div v-for="(item, index) in roomInfo.filelist" :key="index" class="resource-item" :class="{'active': currentIndex == index}">
          <div class="file-item-title" >
<!--            <a download :href="item.fileUrl">{{item.fileName}}</a>-->
            <span @click="downFile(item.fileUrl, index)">{{item.fileName}}</span>
          </div>
        </div>
      </div>
      <div class="messageArea" v-if="tabIndex == 0">

        <div class="interact_chat" v-show="liveStatus !== 0">
          <div class="ChatArea" id="ChatArea">
            <div v-for="(item, index) in msgList" :key="index">
              <ChatBox :avatarUrl="item.avatarUrl" :userName="item.userName" :content="item.userContent"
                       v-if="item.msgType === 'msg'" :direction="item.userId !== userInfo.id ? 'left' : 'right'"/>
              <SystemInfo :userName="item.userName" :content="item.userContent" v-if="item.msgType === 'system'"/>

            </div>
          </div>

        </div>
        <div class="inputArea" v-if="((liveStatus == 1) || (liveStatus == 3) )&& tabIndex == 0">
          <input id="inputTxt" v-model="inputTxt" maxlength="100" type="text"
                 :placeholder="isShutup ? ' 你已被禁言' : '说说你的想法'" @keyup="listenerKeyUp">
          <button :class="(inputTxt.length !== 0 && !isShutup) ? 'activeButton' : 'defaultButton'" @click="sendMessage"
                  type="button">发送
          </button>
        </div>

        <div class="interact_reservation" v-show="liveStatus === 0">
          <div class="topArea">
            <div class="topAreaPlaceholder"></div>
            <div class="topAreaTip">
              <img class="topAreaCaffe" src="@/assets/caffe.png">
              <p class="topAreaText">
                直播还没开始哦!!
              </p>
              <p class="topAreaSecondaryText">
                本次直播预计开始时间: {{ beginTime }}
              </p>
            </div>
          </div>
          <div class="bottomArea">
            <p class="bottomAreaTip bottomAreaText">
              不想错过?立即点击
            </p>
            <button @click="reservation" :class="isReservation ? 'bottomAreaButton disabled' : 'bottomAreaButton'">{{
                isReservation ? "已预约" :
                    '提醒我'
              }}
            </button>
            <p class="bottomAreaExplain bottomAreaText">
              我们将在直播前30分钟以短信方式进行提醒
            </p>
          </div>
        </div>
      </div>

    </div>
   <vue-final-modal v-model="showModal" :click-to-close="false" classes="modal-container"
                     content-class="modal-content">
      <div class="modal_header">
        <p class="modal_header_title">系统通知</p>
        <div class="modal_header_close" @click="showModal = false">
          <img src="@/assets/close.png">
        </div>
      </div>
      <div class="modal_content">
        <span v-show="modalType === 'shutUp'">
          <img class="modal_content_img" src="@/assets/block.png" alt="">
          <p class="modal_content_txt">{{modalContent}}</p>
        </span>
        <span v-show="modalType === 'block'">
          <p class="modal_content_txt">您已被拉黑</p>
        </span>
        <span v-show="modalType === 'reservation'">
          <p class="modal_content_txt">预约成功</p>
        </span>
        <span v-show="modalType === 'liveBlock'">
          <p class="modal_content_txt">主播暂时离开~</p>
        </span>
        <span v-show="modalType === 'unShutUp'">
          <p class="modal_content_txt">您可以正常发言了</p>
        </span>

      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import DPlayer from 'dplayer'
import Hls from "hls.js";
import SocketService from '@/utils/socket.js'
import ChatBox from './component/chatBox'
import SystemInfo from './component/systemInfo'
import {VueFinalModal} from 'vue-final-modal'
import FileSaver from 'file-saver'
import {
  getAppointment,
  getAppointmentState,
  getCurrentLive,
  getInfoByToken,
  getLiveAddress,
  getLiveUserStatus,
  getReplayAddress
} from '@/api'

export default {
  name: 'LivePage',
  components: {
    ChatBox,
    SystemInfo,
    VueFinalModal,
  },
  data() {
    return {
      liveStatus: 0, // 0 代表未开始  1 代表直播中  2 代表直接结束
      inputTxt: '',
      websocketUrl: process.env.VUE_APP_WS,
      userInfo: {},
      roomInfo: {},
      showModal: false,
      modalType: 'shutUp',
      modalContent: "已被禁言",
      isShutup: false,
      userId: 12,
      isBlock: false,
      msgList: [],
      beginTime: '',
      placeholdeImg: '',
      isReservation: false,
      dPlayer: null,
      isShowLive: true,
      tabIndex: 0,
      currentIndex: 0
    }
  },
  created() {
    this.parseUid()
  },
  mounted() {
    this.getLoginInfo()
  },
  beforeDestroy() {
    this.isShowLive = false;
    this.dPlayer.destroy()
  },
  methods: {
    downFile(url, index){
      // 使用FileSaver.js 下載文件
      this.currentIndex = index
      FileSaver.saveAs(url);
    },
    tabClick(tabindex){
      this.tabIndex = tabindex
    },
    initLiveLayer(url) {
      if (!this.isBlock) {
        this.dPlayer = new DPlayer({
          container: document.getElementById("dplayer"),
          loop: false,
          live: true,
          lang: "zh-cn",
          hotkey: false,
          autoplay: true,
          video: {
            type: "customHls",
            url: url, // 视频链接
            customType: {
              customHls: function (video) {
                const hls = new Hls();
                hls.loadSource(video.src);
                hls.attachMedia(video);
              },
            },
          },
        })
      } else {
        this.liveStatus = 0
        this.placeholdeImg = this.roomInfo.cover

      }

    },
    initVideoLayer(url) {
      new DPlayer({
        container: document.getElementById("dplayer"),
        loop: true,
        live: false,
        lang: "zh-cn",
        hotkey: false,
        autoplay: true,
        video: {
          type: "auto",
          url: url, // 视频链接
        },
      })
    },
    listenerKeyUp(event) {
      console.log(event)
      if (event.keyCode == 13) {
        this.sendMessage()
      }
    },
    sendMessage() {
      if (!this.isShutup && this.inputTxt.length !== 0) {
        let initParams = {
          "type": 'message',
          "userid": this.userInfo.id + '',
          "username": this.userInfo.nickname + '',
          "avatar": 'sdsdsd',
          "content": this.inputTxt,
          // "roomid": this.userId
          "roomid": this.liveId
        }
        // 发送信息
        SocketService.instance.send(JSON.stringify(initParams))
        this.inputTxt = ''
      } else {
        console.log('不可发送消息')
      }

    },
    // 查询预约状态
    getReservationState() {
      let params = {
        userId: this.userInfo.id,
        liveId: this.liveId
      }
      getAppointmentState(params)
          .then((res) => {
            console.log(res, '====>getAppointmentState')
            if (res.errno === 200) {
              if (res.data.isAppointment === 1) {
                this.isReservation = true
              }
            } else {
              console.log(res.errmsg)
            }
          })
          .catch(() => {
            console.log('网络异常，请稍后重试')
          })
    },
    // 点击预约按钮
    reservation() {
      let params = {
        userId: this.userInfo.id,
        liveId: this.liveId
      }
      if (this.isReservation) {

        return false
      }
      console.log('预约', params)
      getAppointment(params)
          .then((res) => {
            if (res.errno === 200) {
              this.modalType = 'reservation'
              this.showModal = true
              this.isReservation = true
            } else if (res.errno === 11004) {
              this.isReservation = true
              this.modalType = 'reservation'
              this.showModal = true
            } else {
              alert(res.errmsg)
            }
          })
          .catch(() => {
            console.log('网络异常，请稍后重试')
          })
    },
    // 获取直播详情
    getCurrentLiveDetail() {
      let params = {
        // liveId: this.liveId
        liveId: this.liveId
      }
      getCurrentLive(params)
          .then((res) => {
            console.log(res, '获取直播详情')
            if (res.errno === 200) {
              this.roomInfo = res.data
              // 根据liveStatus判断直播还是回放
              switch (res.data.liveStatus) {
                case 0:
                  // 未开始，需要预约
                  this.placeholdeImg = res.data.cover
                  this.beginTime = res.data.beginTime
                  this.liveStatus = 0
                  break;
                case 1:
                  // 正在直播  1. 获取直播地址 2. 加入聊天室
                  this.liveStatus = 1
                  this.getLiveAddressDetail()
                  this.joinChatRoom()
                  break;
                case 2:
                  // 直播结束 1. 获取回放地址 2. 加入聊天室
                  this.placeholdeImg = res.data.cover
                  this.liveStatus = 2
                  this.joinChatRoom()
                  break;
                case 3:
                  // 直播结束 1. 获取回放地址 2. 加入聊天室
                  this.liveStatus = 3
                  this.getReplayAddressDetail()
                  this.joinChatRoom()
                  break;

                default:
                  break;
              }
            } else {
              console.log(res.errmsg)
            }
          })
          .catch(() => {
            console.log('网络异常，请稍后重试')
          })
    },
    // 获取直播间地址
    getLiveAddressDetail() {
      let params = {
        // liveId: this.liveId
        liveId: this.liveId,
        sharpness: "_lud"
      }
      getLiveAddress(params)
          .then((res) => {
            if (res.errno === 200) {
              this.initLiveLayer(res.data.m3u8Address)
              console.log('获取成功', res.data.m3u8Address)
            } else {
              console.log(res.errmsg)
            }
          })
          .catch(() => {
            console.log('网络异常，请稍后重试')
          })
    },
    // 获取视频回放地址
    getReplayAddressDetail() {
      let params = {
        liveId: this.liveId
      }
      getReplayAddress(params)
          .then(res => {
            if (res.errno === 200) {
              console.log('获取直播回放地址成功', res.data)
              this.initVideoLayer(res.data)
            } else {
              console.log('失败', res.data)
            }
          })
          .catch(err => {
            console.log('网络异常', err)
          })
    },
    // 获取用户信息
    getLoginInfo() {
      // let params = {
      //   userId: this.userId
      // }
      let token = this.getQueryString('token')
      getInfoByToken(token)
          .then(res => {
            console.log(res, '-获取用户信息-')
            if (res.errno === 200) {
              this.userInfo = res.data
              this.getCurrentLiveDetail()
              this.getUserInRoomState()
              this.getReservationState()
            }
          })
          .catch(err => {
            console.log(err)
          })
    },
    // 加入聊天室
    joinChatRoom() {
      localStorage.setItem("userId", this.userInfo.id)
      localStorage.setItem("nickname", this.userInfo.nickname)
      localStorage.setItem("liveId", this.liveId)
      let socketUrl = this.websocketUrl + this.userInfo.id
      SocketService.Instance.socketUrl = socketUrl
      SocketService.Instance.connect(socketUrl)
      SocketService.Instance.registerCallBack(this.getRoomMSG)
      let initParams = {
        "type": 'init',
        "userid": this.userInfo.id + '',
        "username": this.userInfo.nickname + '',
        "avatar": this.userInfo.avatarUrl,
        "content": '',
        // "roomid": this.userId
        "roomid": this.liveId
      }
      // 发送信息
      setTimeout(() => {
        console.log('initParams')

        SocketService.instance.send(JSON.stringify(initParams))
      }, 1000);
    },
    // 判断用户是否被拉黑
    getUserInRoomState() {
      let params = {
        userId: this.userInfo.id,
        liveId: this.liveId
      }
      getLiveUserStatus(params)
          .then(res => {
            if (res.errno === 200) {
              console.log(res.data, ' 判断用户是否被拉黑')
              if (res.data.isBlock === 1) {
                console.log('用户被拉黑')
                this.modalType = 'block'
                this.isShutup = true
                this.showModal = true
                this.isBlock = true
                this.dPlayer && this.dPlayer.destroy()
                this.liveStatus = 0
                this.placeholdeImg = this.roomInfo.cover
              }
              if (res.data.isShutUp === 1) {
                console.log('用户被禁言')

                if(this.roomInfo.openComment==1){
                  this.modalContent="直播已设置全员禁言"
                }
                this.isShutup = true
                this.modalType = 'shutUp'
                this.showModal = true
              }
            }
          })
          .catch(err => {
            console.log(err)
          })
    },
    // 接收聊天室消息
    getRoomMSG(massage) {
      let msgParams
      let msg = JSON.parse(massage)
      console.log('获取ws返回数据', msg)
      switch (msg.type) {
        case 'init':
          // 用户初始化进入直播间
          msgParams = {
            userName: msg.username,
            userId: msg.userId,
            userContent: msg.content,
            msgType: 'system',
            avatarUrl: msg.avatar
          }
          this.msgList.push(msgParams)
          this.$nextTick(() => {
            document.getElementById('ChatArea').scrollTop = 99999
          })
          break;
        case 'message':
          // 用户发送消息
          msgParams = {
            userName: msg.username,
            userId: JSON.parse(msg.userId),
            userContent: msg.content,
            msgType: 'msg',
            avatarUrl: msg.avatar
          }
          console.log('msgParams', msgParams.userId)
          console.log('init2', this.userInfo.id)
          this.msgList.push(msgParams)
          this.$nextTick(() => {
            document.getElementById('ChatArea').scrollTop = 99999
          })
          break;
        case 'shutUp':
          // 用户被禁言
          console.log('shutUp')
          this.isShutup = true
          this.modalType = 'shutUp'
          this.modalContent = msg.content
          this.showModal = true
          break;
        case 'unShutUp':
          // 用户被禁言
          console.log('unShutUp')
          this.isShutup = false
          this.modalType = 'unShutUp'
          this.modalContent = msg.content
          this.showModal = true
          break;
        case 'Block':
          // 用户被拉黑
          console.log('Block')
          this.modalType = 'block'
          this.showModal = true
          this.isShutup = true
          this.isBlock = true
          this.dPlayer && this.dPlayer.destroy()
          this.liveStatus = 0
          this.placeholdeImg = this.roomInfo.cover
          break;
        case 'liveBlock':
          // 用户被拉黑
          this.modalType = 'liveBlock'
          this.showModal = true
          break;
        default:
          console.log('default')
          break;
      }
    },
    // 解析userId，目前参数以url + ?uid=xxx 的形式获取房间号
    parseUid() {

      this.userId = this.getQueryString('uid');
      this.liveId = this.getQueryString('liveId');

      console.log()
    },
    getQueryString(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      let r = location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return null;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.contain {
  width: 1200px;
  height: 100%;
  padding-top: 60px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}

#dplayer {
  width: 800px;
  height: 100%;
}

#placeholdeImg {
  width: 800px;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

.messageArea {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 24px;
  width: 370px;
  //height: 100%;
  overflow: hidden;
  background: #F8F8F8;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  overflow-y: auto;
  // 隐藏滚动条
  //&::-webkit-scrollbar {
  //  width: 0;
  //  height: 16px;
  //  background-color: #97979730;
  //}

  .interact_chat {
    height: 100%;
  }

  .ChatArea {
    min-height: 350px;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 12px 10px 30px;
  }

  .ChatArea::-webkit-scrollbar {
    width: 0;
    // height: 16px;
    // background-color: #97979730;
  }

  // /*定义滚动条轨道 内阴影+圆角*/
  // .ChatArea::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 6px rgba(162, 162, 162, 0.3);
  //   border-radius: 10px;
  //   background-color: #F5F5F5;
  // }

  // /*定义滑块 内阴影+圆角*/
  // .ChatArea::-webkit-scrollbar-thumb {
  //   border-radius: 10px;
  //   -webkit-box-shadow: inset 0 0 6px rgba(162, 162, 162, .3);
  //   background-color: #555;
  // }

  .inputArea {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 10px;

    input {
      padding: 16px 15px 17px 19px;
      width: 270px;
      height: 47px;
      background: #FFFFFF;
      border: 1px solid #E7E7E7;
      box-sizing: border-box;
      border-right: 0;
    }

    input:focus {
      outline: 0;
    }

    input:focus-visible {
      background: #FFFFFF;
      border: 1px solid #2da7e06b;
      /* 解决input按钮自动填充后 浏览器渲染的默认背景问题 */
      box-shadow: inset 0 0 0 1000px #FFFFFF !important;
    }

    button {
      width: 100px;
      height: 47px;
      box-sizing: border-box;
    }

    .defaultButton {
      background: #909399;
      border-color: transparent;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
      cursor: no-drop;
    }

    .activeButton {
      background: #8EBC63;
      border-color: transparent;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
}

.comment {
  font-weight: 600;
  text-align: left;
}

.interact_reservation {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .topArea {
    background-image: url(~@/assets/yuyue_bg.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 305px;

    .topAreaPlaceholder {
      //height: calc(600px - 330px);
    }

    .topAreaTip {
      position: relative;
      top: 83px;

      .topAreaCaffe {
        width: 61px;
        height: 63px;
      }

      .topAreaText {
        margin-top: 29px;
        font-size: 20px;
        font-family: Source Han Sans CN Medium;
        font-weight: 500;
        color: #FFFFFF;
      }

      .topAreaSecondaryText {
        margin-top: 25px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }

  .bottomArea {
    //height: 268px;

    .bottomAreaTip {
      //margin-top: 78px;
    }

    .bottomAreaExplain {
      margin-top: 22px;
    }

    .bottomAreaText {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
    }

    .bottomAreaButton {
      width: 136px;
      height: 40px;
      background: #8EBC63;
      border-radius: 20px;
      border: none;
      margin-top: 15px;
      cursor: pointer;

      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
    }

    .disabled {
      background: #ccc;
      cursor: o-drop;

    }
  }
}
</style>

<style scoped lang="scss">
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  width: 595px;
  height: 341px;
  background: #fff;
}

.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}

.modal_header {
  width: 100%;
  height: 60px;
  background-color: #7FA661;
  display: flex;
  padding: 20px 19px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;

  .modal_header_title {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
  }

  .modal_header_close {
    width: 20px;;
    height: 20px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.modal_content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 280px;

  .modal_content_img {
    margin-bottom: 24px;
    width: 63px;
    height: 63px;
  }

  .modal_content_txt {
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #000000;
    line-height: 40px;
  }
}

.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}

.types-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 10px 15px 5px;
}

.tab-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.tab-list .tab-active, .tab-list li:hover {
  color: #1677ff;
}

.tab-list li[data-v-210f7d14] {
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  cursor: pointer;
  position: relative;
  font-size: 16px;
  color: rgba(0, 0, 0, .88);
  background-color: #fff;
}
li {
  list-style: none;
}

.tab-list .tab-active:after {
  width: 100%;
  left: 0;
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
}

.tab-list li:after {
  content: "";
  width: 0;
  height: 2px;
  background-color: #1677ff;
  position: absolute;
  left: 100%;
  bottom: 0;
  -webkit-transition: all .4s;
  transition: all .4s;
}
ul {
  margin: 0;
  padding: 0;
}

.right-box {
  display: flex;
  flex-direction: column;
  background: #FFF;
}


.list {
  //flex: 1;
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 370px;
  background-color: #f7f7f7;
  font: 12px/1.14 arial;
}
.list .resource-item {
  line-height: 20px;
  -webkit-transition: all .3s;
  transition: all .3s;
  padding: 10px 15px;
  border-radius: 6px;
  color: #000;
}
.list .active {
  background-color: #d4e3fe !important;
}

.file-item-title {
  text-align: left;
}
</style>
